import { useContext, useEffect, useState } from 'react'
import { Table, Popconfirm, Button, message } from "antd"
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { FilterContext } from '../../../providers/FilterProvider'
import { Helmet } from 'react-helmet'
import useVPNBot from '../../../hooks/useVPNBot'

import Spinner from '../../Spinner'

import './index.scss'
import Server from '../../../types/models/server'
import ServersModal from '../../Modal/ServersModal'

const ServersTab = () =>
{
    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const [messageApi, contextHolder] = message.useMessage();
    const { usePagination } = useContext(FilterContext)
    const { useServer, useCountry } = useVPNBot()
    const { getServers, updateServer, deleteServer, servers, loading, success, errors, meta } = useServer
    const { countries }  = useCountry
    const { getPagination, setPagination } = usePagination
    const [dataSource, setDataSource] = useState<any[]>()
    const [visible, setVisible] = useState<boolean>(false)
    const [fields, setFields] = useState<any>({
        id: 0,
        name: '',
        short_name: '',
        country: '',
        limit: 0,
        provider: '',
        countries: [],
        info: '',
        data: {}
    })

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        const newDataSource: Array<any> = [];
        servers?.map((server: Server, index: number) => {
           newDataSource.push({
                key:         index,
                id:          server.id,
                short_name:  server.short_name,
                name:        server.name,
                description: server.description,
                country:     server.country.name,
                limit:       server.limit,
                provider:    server.provider,
                createdAt:   server.created_at,
                api_url:     server.api_url,
                crt_hash:    server.crt_hash,
                login:       server.login,
                password:    server.password,
                inbound_id:  server.inbound_id,
                status:      server.status,
                available:   server.available,
                purchased:   server.purchased,
                load:        server.load,
                type:        server.type
            })
        })
        setDataSource(newDataSource)
    }, [servers])

    useEffect(() => {
        if (success)
            setVisible(false)
    }, [loading])

    useEffect(() => {
        messageApi.destroy()
        errors?.map((value: string, index: number) => {
            messageApi.open({
                type: 'error',
                content: value,
            });
        });
    }, [errors])

    //-------------------------------------------------------------------------------
    //                                  HANDLERS
    //-------------------------------------------------------------------------------

    const onServerEdit = (record: Server) =>
    {
        setVisible(true)
        setFields({
            id: record.id,
            name: record.name,
            short_name: record.short_name,
            country: record.country.toString(),
            limit: record.limit,
            provider: record.provider,
            countries: countries ?? [],
            info: record.type === 'outline' ? JSON.stringify({ apiUrl: record.api_url, certSha256: record.crt_hash }) : '',
            api_url: record.api_url,
            type: record.type,
            login: record.login,
            password: record.password,
            inbound_id: record.inbound_id
        })
    }

    const onServerUpdate = () =>
    {
        const server = { ...fields } as Server
        updateServer(server, fields.info)
    }

    const onServerDelete = (id: number) =>
    {
        deleteServer(id)
    }

    const onFieldChange = (name: string, value: string) =>
    {
        setFields({
            ...fields,
            [name]: value
        })
    }

    const handleChange = (pagination: any, filters: any, sorter: any) =>
    {
        setPagination(pagination.current)
        getServers(undefined, pagination.current)
    }

    //-------------------------------------------------------------------------------
    //                               TABLE COLUMNS
    //-------------------------------------------------------------------------------

    const columns = [
        {
            title: 'Creation Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: any, record: any) => <>
                <a href={'servers/' + record.id}>{text}</a>
            </>
        },
        {
            title: 'Short Name',
            dataIndex: 'short_name',
            key: 'short_name',
        },
        {
            title: 'Provider',
            dataIndex: 'provider',
            key: 'provider',
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
        },
        {
            title: 'Load',
            dataIndex: 'load',
            key: 'load',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'API URL',
            dataIndex: 'apiUrl',
            key: 'apiUrl',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: any, record: any) => <>
                <Button type='default' className={`status px-2 ${text.replace(' ', '-')}`}>{text}</Button>
            </>
        },
        {
            title: ' ',
            key: '_',
            render: (text: any, record: any) => <>
                <EditOutlined onClick={() => onServerEdit(record)} className='copy-icon mr-3'/>
                <Popconfirm
                    placement="top"
                    title={"Are you sure to delete this promocode?"}
                    onConfirm={() => onServerDelete(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteOutlined className='delete-icon'/>
                </Popconfirm>
            </>
        }
    ];

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------

    return (
        <>
            {contextHolder}
            <Helmet>
                <title>Servers - VPNBot Admin</title>
            </Helmet>
            <ServersModal
                title={'Edit server'}
                buttonTitle={'Save'}
                source={fields}
                show={visible}
                handleShow={(state: boolean) => setVisible(state)}
                handleSource={onFieldChange}
                handleApply={onServerUpdate}
            />
            {
                !loading ?
                    <Table className={"servers__tab"} dataSource={dataSource} columns={columns} onChange={handleChange} pagination={meta?.last_page === 1 ? false : {total: meta?.total, pageSize: meta?.per_page, current: getPagination()}}/>
                :
                    <div className='flex justify-items-center items-center flex-col w-full'>
                        <Spinner/>
                    </div>
            }
        </>
    )
}

export default ServersTab