import React, { useState } from 'react';
import { Layout, Breadcrumb } from 'antd';
import { Helmet } from 'react-helmet';
import { Content } from 'antd/es/layout/layout';

import Nav from '../../components/Nav';
import Header from '../../components/Header';
import UserTab from '../../components/Tab/UserTab';

const UserDetailPage = () => {
    const [clientName, setClientName] = useState<string>('');
    const breadcrumbs = [
        { title: 'Clients', href: '/clients' },
        { title: clientName || 'Детали клиента' },
    ];

    return (
        <>
            <Helmet>
                <title>Детали клиента - VPNBot Admin</title>
            </Helmet>
            <Layout style={{ minHeight: '100vh' }}>
                <Header/>
                <Layout>
                    <Nav/>
                    <Layout className="site-layout">
                        <div className="tab__menu">
                            <Breadcrumb className="breadcrumbs" items={breadcrumbs} />
                        </div>
                        <Content className="bg-[#f0f2f5]" style={{ margin: '0 16px' }}>
                            <div className='mt-[32px] p-[24px] bg-white rounded-[4px]'>
                                <UserTab onClientNameChange={setClientName} /> {}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </>
    );
};

export default UserDetailPage;
