import Item from '../types/models/item'
import Server from '../types/models/server'
import {request} from './index'
import TokenConfig from './token'

const getServers = (item?: Item["name"], type?: Server["type"], page?: number) => {
    return request.get('servers', {headers: TokenConfig().headers, params: {
        page: page,
        type: type,
        item: item
    }});
}

const getServer = (id: number) =>
{
    return request.get(`servers/${id}`, {headers: TokenConfig().headers});
}

const addServer = (server: Server, info?: string) =>
{
    const params = {
        name: server.name,
        short_name: server.short_name,
        limit: server.limit,
        provider: server.provider,
        country: server.country,
        api_url: server.api_url,
        type: server.type,
        data: {},
        info: ''
    };

    if (server.type === 'outline' && info) {
        params.info = JSON.parse(info);
    } else {
        params.data = {
            login: server.login,
            password: server.password,
            inbound_id: server.inbound_id
        }
    }

    return request.post('servers', params, { headers: TokenConfig().headers });
};

const updateServer = (server: Server, info?: string) => {
    const params = {
        name: server.name,
        short_name: server.short_name,
        limit: server.limit,
        provider: server.provider,
        country: server.country,
        api_url: server.api_url,
        type: server.type,
        data: {},
        info: ''
    };

    if (server.type === 'outline' && info) {
        params.info = JSON.parse(info);
    } else {
        params.data = {
            login: server.login,
            password: server.password,
            inbound_id: server.inbound_id
        }
    }

    return request.put(`servers/${server.id}`, params, { headers: TokenConfig().headers });
};


const deleteServer = (id: number) =>
{
    return request.delete(`servers/${id}`, TokenConfig());
}

const sendServerMessage = (id: number, message: string) =>
{
    return request.post(`servers/${id}/messages/send`, {
        message: message
    } , { headers: TokenConfig().headers })
}

export { getServers, getServer, addServer, updateServer, deleteServer, sendServerMessage};