import { useContext, useEffect, useState } from 'react'
import { Popconfirm, Table, message } from "antd"
import { DeleteOutlined, CopyOutlined } from '@ant-design/icons'
import { FilterContext } from '../../../providers/FilterProvider'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'
import Client from '../../../types/models/client'

import useVPNBot from '../../../hooks/useVPNBot'
import Spinner from '../../Spinner'

import './index.scss'
import Item from '../../../types/models/item'
import Key from '../../../types/models/key'
import Tariff from '../../../types/models/tariff'



//TODO Add IColumns interface for table from newDataSource

const UsersTab = () =>
{

    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const [ messageApi, contextHolder ] = message.useMessage()
    const { usePagination } = useContext(FilterContext)
    const { useClient } = useVPNBot()
    const [dataSource, setDataSource] = useState<any[]>()
    const {getClients, deleteClient, clients, loading, meta} = useClient
    const {getPagination, setPagination} = usePagination

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        const newDataSource: Array<any> = [];
        clients?.map((client: Client, index: number): void => {
            newDataSource.push({
                key:             index,
                id:              client.id,
                created_at:      client.created_at,
                name:            client.name.length ? client.name : ("#" + client.chat_id),
                item:            client.tariffs,
                servers:         client.tariffs,
                expirated_at:    client.tariffs,
                keys:            client.tariffs
            })
        })
        setDataSource(newDataSource)
    }, [clients])

    useEffect(() => {
        getClients()
    }, [])

    //-------------------------------------------------------------------------------
    //                                  HANDLERS
    //-------------------------------------------------------------------------------

    const handleChange = (pagination: any, filters: any, sorter: any) =>
    {
        setPagination(pagination.current)
        getClients(pagination.current)
    }

    const copyKey = (key: string) =>
    {
        navigator.clipboard.writeText(key)
        messageApi.open({
            type: 'success',
            content: `Key successfully copied! `,
        });
    }

    const onDelete = (id: Client["id"]) => deleteClient(id)

    const navigate = useNavigate();

    const handleEditClick = (id: number) => {
        navigate(`/clients/${id}`);
    };

    //-------------------------------------------------------------------------------
    //                                  TABLE COLUMNS
    //-------------------------------------------------------------------------------

    const columns = [
        {
            title: 'Creation date',
            dataIndex: 'created_at',
            key: 'created_at'
        },
        {
            title: 'Username',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
            render: (tariffs: any[], record: any) => (
                <>
                    {Array.isArray(tariffs) && tariffs.length > 0 ? (
                        tariffs.map((tariff: any) => (
                            <div key={tariff.id}>
                                {tariff.item.name}
                            </div>
                        ))
                    ) : (
                        <span>-</span>
                    )}
                </>
            )
        },
        {
            title: 'Expiration dates',
            dataIndex: 'expirated_at',
            key: 'expirated_at',
            render: (tariffs: any[], record: any) => (
                <>
                    {Array.isArray(tariffs) && tariffs.length > 0 ? (
                        tariffs.map((tariff: any) => (
                            <div key={tariff.id}>
                                {tariff.expired_at}
                            </div>
                        ))
                    ) : (
                        <span>-</span>
                    )}
                </>
            )
        },
        {
            title: 'Servers',
            dataIndex: 'servers',
            key: 'servers',
            render: (tariffs: any[], record: any) => (
                <>
                    {Array.isArray(tariffs) && tariffs.length > 0 ? (
                        tariffs.map((tariff: any) => (
                            <div key={tariff.id}>
                                {tariff.key && tariff.key.server ? (
                                    <a href={'/servers/' + tariff.key.server.id}>
                                        {tariff.key.server.short_name} ({tariff.key.server.status})
                                    </a>
                                ) : (
                                    <span>No server information available</span>
                                )}
                            </div>
                        ))
                    ) : (
                        <span>No tariffs available</span>
                    )}
                </>
            )
        },
        {
            title: 'Keys',
            dataIndex: 'keys',
            key: 'keys',
            render: (tariffs: any[], record: any) => (
                <>
                    {Array.isArray(tariffs) && tariffs.length > 0 ? (
                        tariffs.map((tariff: any) => (
                            <div key={tariff.id}>
                                {tariff.key && tariff.key.access_url ? (
                                    <>
                                        <CopyOutlined onClick={() => copyKey(tariff.key.access_url)}
                                                      className='copy-icon mr-3'/>
                                        {tariff.key.access_url.substring(0, 10)}
                                    </>
                                ) : (
                                    <span>-</span>
                                )}
                            </div>
                        ))
                    ) : (
                        <span>-</span>
                    )}
                </>
            )
        },
        {
            title: 'Actions',
            key: 'id',
            width: 150,
            render: (text: any, record: any) => <>
                <EditOutlined
                    className='copy-icon mr-3'
                    onClick={() => handleEditClick(record.id)}
                />
                <Popconfirm
                    placement="top"
                    title={"Are you sure to delete this user?"}
                    onConfirm={() => onDelete(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteOutlined className='delete-icon'/>
                </Popconfirm>
            </>
        }
    ]

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------

    return (
        <>
            <Helmet>
                <title>Purchases - VPNBot Admin</title>
            </Helmet>
            {contextHolder}
            {
                !loading ?
                    <Table dataSource={dataSource} columns={columns} onChange={handleChange} pagination={meta?.last_page === 1 ? false : {total: meta?.total, pageSize: meta?.per_page, current: getPagination()}}/>
                    :
                    <div className='flex justify-items-center items-center flex-col w-full'>
                        <Spinner/>
                    </div>
            }
        </>
    )
}

export default UsersTab