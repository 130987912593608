import { useState, useEffect, useCallback } from 'react';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { ClientDetail } from '../types/models/clientDetail';
import { getClientById } from "../api/clientAPI";

const useDetailClient = (userId: number) => {
    const dispatch = useAppDispatch();
    const selector = useAppSelector((state) => state.clients);
    const [client, setClient] = useState<ClientDetail | null>(null);
    const [loading, setLoading] = useState(true);
    const [errors] = useState(selector.errors);

    const fetchClient = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getClientById(userId);
            setClient(response.data.data);
        } catch (error) {
            console.error("Ошибка при загрузке данных клиента:", error);
        } finally {
            setLoading(false);
        }
    }, [userId]);

    useEffect(() => {
        if (userId) {
            fetchClient();
        }
    }, [userId, fetchClient, dispatch]);

    return {
        client,
        loading,
        errors,
        refetch: fetchClient
    };
};

export default useDetailClient;