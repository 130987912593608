import Chart, {CategoryScale} from "chart.js/auto";

Chart.register(CategoryScale)

Chart.register({
    id: "PieChartEmptyHandler",
    afterDraw: (chart) => {
        const isDatasetEmpty = chart.data.datasets[0].data.every(item => item === 0)
        if (!isDatasetEmpty) {
            return
        }

        let ctx = chart.ctx;
        let width = chart.width;
        let height = chart.height;

        chart.clear();
        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('No data to display', width / 2, height / 2);
        ctx.restore();
    }
})

export {Chart};
