import {AnalyticsClientStatistics, AnalyticsDateRange} from "../../types/models/analytics";
import {ComponentProps, useMemo, useState} from "react";
import {Radio, RadioChangeEvent} from 'antd';
import {Bar} from "react-chartjs-2";
import {ChartData} from 'chart.js/auto';

interface AnalyticsBarChartProps {
    statistics: AnalyticsClientStatistics
    title: string
}

const DATE_RANGE_OPTIONS = Object.values(AnalyticsDateRange)


const CHART_OPTIONS: ComponentProps<typeof Bar>['options'] = {
    scales: {
        y: {
            ticks: {
                stepSize: 1,
            }
        },
    },
    plugins: {
        legend: {
            display: false,
        }
    }
}

export const AnalyticsBarChart = ({statistics, title}: AnalyticsBarChartProps) => {
    const [selectedDateRange, setSelectedDateRange] = useState<AnalyticsDateRange>(AnalyticsDateRange.MONTH)

    const selectedDataByRange = useMemo(() => {
        return statistics[selectedDateRange]
    }, [selectedDateRange, statistics])

    const selectedChartData = useMemo((): ChartData<"bar"> => {
        return {
            labels: Object.keys(selectedDataByRange),
            datasets: [
                {
                    data: Object.values(selectedDataByRange),
                },
            ],
        }
    }, [selectedDataByRange])

    const handleDateRangeChange = (event: RadioChangeEvent) => {
        setSelectedDateRange(event.target.value)
    }

    return (
        <div className="flex flex-col gap-8">
            <div className="flex flex-row items-center justify-between">
                <span className="text-lg font-semibold">{title}</span>
                <Radio.Group
                    options={DATE_RANGE_OPTIONS}
                    value={selectedDateRange}
                    onChange={handleDateRangeChange}
                    optionType="button"
                />
            </div>
            <Bar data={selectedChartData} options={CHART_OPTIONS} className="max-h-[350px]"/>
        </div>
    )
}
