import { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Modal, Row, Select } from "antd"
import useVPNBot from '../../../hooks/useVPNBot'

interface ISource
{
    id: number,
    short_name: string,
    name: string,
    country: string,
    limit: number,
    provider: string,
    info: string,
    type: string,
    api_url?: string,
    login?: string,
    password?: string,
    inbound_id?: string
}

interface IProps
{
    title: string,
    buttonTitle: string,
    show: boolean,
    handleShow: ((state: boolean) => any)
    source: ISource,
    handleSource: ((name: string, value: any) => any),
    handleApply: (() => void)
}


const ServersModal = ({title, buttonTitle, show, handleShow, source, handleSource, handleApply}: IProps) =>
{
    const {useCountry} = useVPNBot()
    const { countries } = useCountry
    const [form] = Form.useForm()

    useEffect(() => form.setFieldsValue(source), [source])

    return (
        <Modal
            title={title}
            centered
            open={show}
            onOk={form.submit}
            onCancel={() => handleShow(false)}
            footer={[
                <Button key="cancel" onClick={() => handleShow(false)}>
                    Cancel
                </Button>,
                <Button form={title} key="submit" htmlType="submit" type='primary'>
                    {buttonTitle}
                </Button>
            ]}
        >
            <Form
                name={title}
                autoComplete="off"
                layout="vertical"
                id={title}
                form={form}
                onFinish={handleApply}
                initialValues={source}
            >
                <Row>
                    <Col span={12} className="pr-1">
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input server name' }]}>
                            <Input placeholder='Name' name='name' value={source.name} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={12}  className="pl-1">
                        <Form.Item
                            label="Short name"
                            name="short_name"
                            rules={[{ required: true, message: 'Please input server name' }]}>
                            <Input placeholder='Short name' name='short_name' value={source.short_name} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}  className="pr-1">
                        <Form.Item
                            label="Server key limit"
                            name="limit"
                            rules={[{ required: true, message: 'Please input server limit' }]}>
                            <Input type='number' placeholder='server limit' name='limit' value={source.limit} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="pl-1">
                        <Form.Item
                            label="Provider"
                            name="provider"
                            rules={[{ required: true, message: 'Please input server provider'}]}>
                            <Input placeholder='provider' name='provider' value={source.provider} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Country"
                            name="country"
                            rules={[{ required: true, message: 'Please select country'}]}>
                            <Select
                                showSearch
                                value={source.country ?? undefined}
                                onChange={value => handleSource('country', value)}
                                options={countries?.map((name: string, index: number) => { return { name: name, value: name } })} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="Server type"
                            name="type"
                            rules={[{ required: true, message: 'Please select type'}]}>
                            <Select
                                showSearch
                                value={source.type ?? undefined}
                                onChange={value => handleSource('type', value)}
                                options={[
                                    { label: 'Outline', value: 'outline' },
                                    { label: '3x-ui', value: '3x-ui' }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {source.type === 'outline' && (
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Server payload"
                                name="info"
                                rules={[{ required: true, message: 'Please input server payload' }]}>
                                <Input placeholder='{"apiUrl":"https://49.13.20.94:62916/J1JiZ-_YSb9u1fOqWY-Ojg","certSha256":"F39A0D3880CF604EAF046384E4A52A26176E2A27C63DC9C630AF25D2B36E45B6"}' name='info' value={source.info} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                            </Form.Item>
                        </Col>
                    </Row>
                )}

                {source.type === '3x-ui' && (
                    <>
                        <Row>
                            <Col span={14}>
                                <Form.Item
                                    label="Server Path"
                                    name="api_url"
                                    rules={[{ required: true, message: 'Please input server path'}]}>
                                    <Input placeholder='Server Path' name='api_url' value={source.api_url} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                                </Form.Item>
                            </Col>
                            <Col span={10} className="pl-1">
                                <Form.Item
                                    label="Inbound ID"
                                    name="inbound_id"
                                    rules={[{ required: true, message: 'Please input inbound id'}]}>
                                    <Input placeholder='Inbound ID' name='inbound_id' value={source.inbound_id} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Login"
                                    name="login"
                                    rules={[{ required: source.type === '3x-ui', message: 'Please input login' }]}>
                                    <Input placeholder='Login' name='login' value={source.login || ''} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                                </Form.Item>
                            </Col>
                            <Col span={12} className="pl-1">
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{ required: source.type === '3x-ui', message: 'Please input password' }]}>
                                    <Input.Password placeholder='Password' name='password' value={source.password || ''} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
            </Form>
        </Modal>
    );
}

export default ServersModal
