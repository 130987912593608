import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { useEffect, useState } from "react";
import Server from '../../../types/models/server';
import { editTariffCountry } from '../../../api/tariffAPI';

interface IUserSource {
    name: string;
    price: number;
    period: number;
    invisible: boolean;
    servers: Array<Server>;
    type: string;
    current_server: Server;
    current_server_type: string;
    current_country: string;
    uuid: string;
}

interface IProps {
    title: string;
    buttonTitle: string;
    show: boolean;
    handleShow: (state: boolean) => void;
    source: IUserSource;
    handleSource: (name: string, value: any) => any;
    handleApply: () => void;
}

const UserModal = ({ title, buttonTitle, show, handleShow, source, handleSource, handleApply }: IProps) => {
    const { Option } = Select;
    const [form] = Form.useForm();
    const [isServerChanged, setIsServerChanged] = useState<boolean>(false);
    const [currentServerType, setCurrentServerType] = useState<string>(source.current_server_type);

    useEffect(() => {
        form.setFieldsValue(source);
        setIsServerChanged(false);
        setCurrentServerType(source.current_server_type);
    }, [form, source]);

    const handleCurrentServerChange = (value: string) => {
        setIsServerChanged(value !== source.current_country);
    };

    const handleProtocolChange = (value: string) => {
        setCurrentServerType(value);
        handleSource('current_server_type', value);
        handleSource('current_country', '');
        form.setFieldsValue({ current_country: '' });
        setIsServerChanged(false);
    };

    const onFinish = async (values: IUserSource) => {
        try {
            await editTariffCountry(source.uuid, values.current_country, values.current_server_type);
            handleApply();
            handleShow(false);
        } catch (error) {
            console.error("Error editing tariff:", error);
        }
    };

    const filteredServers = (source?.servers || []).filter(
        (server) => server.type === currentServerType
    );

    const uniqueServers = Array.from(
        new Map(filteredServers.map(server => [server.country.short, server])).values()
    );

    return (
        <Modal
            title={title}
            centered
            open={show}
            onCancel={() => handleShow(false)}
            footer={[
                <Button key="cancel" onClick={() => handleShow(false)}>
                    Cancel
                </Button>,
                <Button
                    form={title}
                    key="submit"
                    htmlType="submit"
                    disabled={!isServerChanged}
                >
                    {buttonTitle}
                </Button>
            ]}
        >
            <Form
                name={title}
                autoComplete="off"
                layout="vertical"
                form={form}
                onFinish={onFinish}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input the item name' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="Protocol"
                            name="current_server_type"
                        >
                            <Select
                                value={source.current_server_type}
                                onChange={handleProtocolChange}
                            >
                                <Option value="outline">shadowsocks</Option>
                                <Option value="3x-ui">vless</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12} className="pl-1">
                        <Form.Item
                            label="Выбрать сервер"
                            name="current_country"
                            rules={[{ required: true, message: 'Выберите сервер' }]}
                        >
                            <Select
                                onChange={handleCurrentServerChange}
                                value={source.current_country}
                                disabled={!currentServerType}
                            >
                                {Array.from(uniqueServers).map((server: Server) => (
                                    <Option value={server.country.short} key={server.country.short}>
                                        {server.country.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default UserModal;