import { useState, useEffect, useContext } from 'react'
import { Input, message } from 'antd'
import { FilterContext } from '../../../providers/FilterProvider'
import useVPNBot from '../../../hooks/useVPNBot'

const ReferralsMenu = () =>
{
    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const { useFilter } = useContext(FilterContext)
    const { useReferral } = useVPNBot()
    const { getFilter, setFilter } = useFilter
    const { getReferrals, errors } = useReferral
    const [messageApi, contextHolder] = message.useMessage()
    const [searchName, setSearchName] = useState(getFilter('searchField') === 'name' ? getFilter('searchValue') : undefined)
    const [searchDelay, setSearchDelay] = useState<NodeJS.Timeout>()

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        messageApi.destroy()
        errors?.forEach((value: string, _index: number) => {
            messageApi.open({
                type: 'error',
                content: value,
            });
        });
    }, [errors, messageApi])


    //-------------------------------------------------------------------------------
    //                                  HANDLERS
    //-------------------------------------------------------------------------------

    //Search filter
    const onUsernameSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    {
        setSearchName(e.currentTarget.value)
        if (searchDelay)
        {
            clearTimeout(searchDelay)
            setSearchDelay(undefined)
        }
        setSearchDelay(setTimeout((name) => {
            setSearchDelay(undefined)
            setFilter('searchField', 'name')
            setFilter('searchValue', name)
            getReferrals()
        }, 500, e.currentTarget.value))
    }

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------


    return <>
        {contextHolder}
        <div className='ml-auto flex items-center'>
            <span className='mr-1'>Search: <Input className='w-[270px]' onChange={onUsernameSearch} value={searchName}/></span>
        </div>
    </>
}

export default ReferralsMenu
